/* eslint-disable prefer-rest-params */
const chargeTypes = [
  {
    key: 'box_prep_material',
    value: 0,
    text: 'Box Prep Material',
  },
  {
    key: 'prep_material',
    value: 0,
    text: 'Item Prep Material',
  },
  {
    key: 'item_prep_charge',
    value: 1,
    text: 'Item Prep Charge',
  },
  {
    key: 'bundle_prep_charge',
    value: 2,
    text: 'Bundle Prep Charge',
  },
  {
    key: 'service',
    value: 3,
    text: 'Service',
  },
  {
    key: 'storage',
    value: 4,
    text: 'Storage',
  },
  {
    key: 'shipping',
    value: 5,
    text: 'Shipping',
  },
  {
    key: 'return_charge',
    value: 6,
    text: 'Return Charge',
  },
  {
    key: 'extra_charge',
    value: 7,
    text: 'Extra Charge',
  },
  {
    key: 'fbm_pick_pack_charge',
    value: 11,
    text: 'FBM Pick/Pack Charge',
  },
  {
    key: 'problem',
    value: 12,
    text: 'Problem',
  },
  {
    key: 'other',
    value: 10,
    text: 'Other',
  },
]

export default {
  getChargeTypes() {
    const args = Array.from(arguments)
    if (args.length > 0) {
      const result = []
      args.forEach(item => {
        result.push(item)
      })
      return result
    }
    return chargeTypes
  },
}
