/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import invoiceService from '@/http/requests/finance/invoiceService'
import Pager from '@core/dev-extreme/utils/pager'
import Filters from '@core/dev-extreme/utils/filters'

const invoiceDetailStore = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    const options = {}
    const pager = new Pager(options)
    if (typeof loadOptions.skip === 'undefined' && typeof loadOptions.take === 'undefined') {
      loadOptions.skip = 0
      loadOptions.take = 5000
    }
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    const pagebleString = pager.toCreatePageable()
    const params = `${pagebleString}`

    const filter = new Filters(loadOptions.filter)
    const filterItem = filter.toCreatePostData()
    const response = await invoiceService.fetchAllItemsByInvoiceId(filterItem.id, params)
    const data = response.data
    return {
      data: data.content,
      totalCount: data.totalElements,
    }
  },
})

const invoiceDetailSource = new DataSource({
  store: invoiceDetailStore,
})

export {
  invoiceDetailSource,
}
