import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  // get invoice by id
  fetchById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.invoice.Controller.findById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // invoice info
  async getInvoiceInfoById(id) {
    const response = await axios.get(endpoints.invoice.Controller.getInvoiceInfoById(id))
    return response.data.body
  },

  async calculatePreviewTotal() {
    const response = await axios.get(endpoints.invoice.Controller.calculatePreviewTotal())
    return response.data.body
  },

  async calculateTotalDue() {
    const response = await axios.get(endpoints.invoice.Controller.calculateTotalDue())
    return response.data.body
  },

  dryRunInvoicesForCompaniesByWarehouse(warehouseId, accountNo) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.invoice.Controller.dryRunInvoicesForCompaniesByWarehouse(warehouseId, accountNo))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get invoices by query
  async fetchAllByQuery(params, pageable) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.invoice.Controller.fetchAllByQuery(pageable), params)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get invoice items vategories
  async fetchAllItemsCategoriesByInvoiceId(invoiceId, pageable) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.invoice.Controller.findAllItemsCategoriesByInvoiceId(invoiceId, pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get invoice items
  async fetchAllItemsByInvoiceId(invoiceId, pageable) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.invoice.Controller.findAllItemsByInvoiceId(invoiceId, pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get invoice items for print
  async getDataForPrintInvoice(invoiceId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.invoice.Controller.getDataForPrintInvoice(invoiceId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // pay invoice by amount
  async payInvoiceByIdAndAmount(params) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.invoice.Controller.payInvoiceByIdAndAmount(params))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async payInvoiceByCreditCard(invoiceId, amount, creditCardId) {
    const response = await axios.put(endpoints.invoice.Controller.payInvoiceByCreditCard(invoiceId, amount, creditCardId))
    return response.data.body
  },

  // generate invoice
  async generateInvoices(params) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.invoice.Controller.generateInvoices(), params)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // create invoice
  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.invoice.Controller.create(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // update invoice
  async update(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.invoice.Controller.update(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // delete invoice
  async deleteById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.invoice.Controller.delete(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // void transaction
  async voidTransaction(id, transactionId, amount) {
    const response = await axios.post(endpoints.invoice.Controller.voidTransaction(id, transactionId, amount))
    return response
  },

  // refund transaction
  async refundTransaction(id, transactionId, amount) {
    const response = await axios.post(endpoints.invoice.Controller.refundTransaction(id, transactionId, amount))
    return response
  },
}
