<template>
  <div class="card mb-0 p-0">
    <div class="card-header px-1 py-half bg-primary">
      <div class="d-flex w-100 align-items-center justify-content-between">
        <div class="mr-auto p-0 align-self-center">
          <h4 class="font-weight-normal mb-0">
            Charges
          </h4>
        </div>
        <div class="p-0 mr-1 align-self-center">
          <dx-util-check-box
            v-model="isUpdateDefaultCharges"
            :visible="visibleDefaultCharge"
            text="Update Default Charges"
            @value-changed="onUpdateDefaultCharges"
          />
        </div>
        <div class="p-0 mr-1 align-self-center">
          <span class="badge badge-secondary badge-pill">{{ chargeItems.length }} Charge added</span>
        </div>
        <div v-if="hasPermission || isIndividualTenat" class="p-0 align-self-center">
          <button class="btn btn-success p-half" :disabled="isDisabled" @click="addBoxCharge()">
            <p-icon name="feather-plus" size="12px" class="mr-50" />
            <span class="align-start">Add Charges</span>
          </button>
        </div>
      </div>
    </div>
    <div class="card-body border p-1 mb-0">
      <div v-for="(item, index) in chargeItems" :key="item.rowId" class="d-flex flex-column mb-1">
        <div class="d-flex flex-row row">
          <div v-if="index===0" class="align-self-center col-sm-3">
            <span class="align-self-center m-0">
              Charge Type
            </span>
          </div>
          <div v-if="index===0" class="align-self-center col-sm-4">
            <span>
              Charge Name
            </span>
          </div>
          <div v-if="index===0" class="align-self-center col-sm-2">
            <span>
              Rate
            </span>
          </div>
          <div v-if="index===0" class="align-self-center col-sm-2">
            <span>
              Count
            </span>
          </div>
          <div v-if="index===0" class="align-self-center col-sm-1" />
        </div>
        <div class="d-flex align-items-center justify-content-between row">
          <div class="align-self-center col-sm-3">
            <dx-util-select-box
              v-model="item.chargeType"
              :data-source="chargeTypes"
              :drop-down-options="{width: '180px'}"
              display-expr="text"
              value-expr="key"
              :disabled="isDisabled"
              @value-changed="changeBoxChargeType($event, item.rowId)"
            />
          </div>
          <div v-if="item.rowIsPrepMaterial" class="align-self-center col-sm-4">
            <dx-util-autocomplete
              v-model="item.costItem"
              :data-source="costItems"
              :min-search-length="3"
              :max-item-count="200"
              :show-clear-button="true"
              :disabled="isDisabled"
              value-expr="title"
              search-expr="title"
              placeholder="Prep Material Name"
              @selection-changed="setBoxChargeItemData($event, item.rowId)"
            >
              <dx-util-validator>
                <dx-util-required-rule message="Count is required" />
              </dx-util-validator>
            </dx-util-autocomplete>
          </div>
          <div v-else class="align-self-center col-sm-4">
            <dx-util-text-box v-model="item.notes" placeholder="Charge Note" />
          </div>
          <div class="align-self-center col-sm-2">
            <dx-util-number-box
              v-model="item.rate"
              :disabled="isDisabled"
              :element-attr="inputClass"
              :read-only="item.rowIsPrepMaterial"
              :hover-state-enabled="false"
            />
          </div>
          <div class="align-self-center col-sm-2">
            <dx-util-number-box
              v-model="item.count"
              :disabled="isDisabled"
              :element-attr="inputClass"
              :min="1"
              :max="maxInt"
              :show-spin-buttons="false"
            >
              <dx-util-validator>
                <dx-util-required-rule message="Count is required" />
              </dx-util-validator>
            </dx-util-number-box>
          </div>
          <div class="align-self-center col-sm-1">
            <dx-util-button
                v-if="hasPermission || isIndividualTenat"
                :disabled="isDisabled"
                type="danger" icon="bi bi-x-lg"
                hint="Delete"
                @click="removeBoxCharge(index)"
              />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import prepMaterialInventoryService from '@/http/requests/prep-material/prepMaterialInventoryService'
import { v4 as uuidv4 } from 'uuid'
import { Notify } from '@robustshell/utils/index'
import find from 'lodash/find'
import filter from 'lodash/filter'
import store from '@/store'
import itemChargeService from '@/http/requests/finance/itemChargeService'
import productsService from '@/http/requests/product/productsService'
import useCurrentUser from '@/libs/app/current-user'
import charge from './chargeType'
import chargeStoreModule from './chargeStoreModule'

const CHARGE_STORE_MODULE = 'charge'
if (!store.hasModule(CHARGE_STORE_MODULE)) store.registerModule(CHARGE_STORE_MODULE, chargeStoreModule)

export default {
  name: 'ChargeItem',
  props: {
    itemId: {
      type: Number,
      required: false,
      default: null,
    },
    productId: {
      type: Number,
      required: false,
      default: null,
    },
    oldChargeItems: {
      type: Array,
      required: false,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      require: false,
      default: () => false,
    },
    visibleDefaultCharge: {
      type: Boolean,
      require: false,
      default: () => true,
    },
    variant: {
      type: String,
      require: false,
      default: () => 'info',
    },
    isSelectedType: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isItemId: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  data() {
    return {
      isUpdateDefaultCharges: true,
      inputClass: {
        class: 'w-100',
      },
      variants: {
        header: '',
        body: '',
      },
      chargeTypes: [],
      chargeItems: [],
      costItems: [],
      chargeItem: {
        id: null,
        itemId: this.itemId,
        costId: 0,
        chargeType: null,
        rate: 0,
        count: 0,
        notes: null,
      },
    }
  },
  setup() {
    const { isIndividualTenat } = useCurrentUser()
    return {
      isIndividualTenat,
    }
  },
  computed: {
    maxInt() {
      return Number.MAX_SAFE_INTEGER
    },
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
  watch: {
    itemId: {
      immediate: true,
      handler(current, previous) {
        if (this.isItemId && current && current > 0) {
          this.getChargeItems(current)
        }
      },
    },
    productId: {
      immediate: true,
      handler(current, previous) {
        if (!this.isItemId && current && current > 0) {
          this.getDefaultChargeItems(current)
        }
      },
    },
  },
  mounted() {
    this.getCostItems()
    this.setDefaultChargeItems()
    if (this.isSelectedType) {
      this.chargeTypes = charge.getChargeTypes(
        { key: 'prep_material', value: 0, text: 'Item Prep Material' },
        { key: 'service', value: 3, text: 'Service' },
        { key: 'shipping', value: 5, text: 'Shipping' },
        { key: 'other', value: 10, text: 'Other' },
      )
    } else {
      this.chargeTypes = charge.getChargeTypes()
    }
  },
  beforeDestroy() {
    store.dispatch('charge/clearCharges')
    this.chargeItems.splice(0, this.chargeItems.length)
  },
  methods: {
    onUpdateDefaultCharges(e) {
      this.$emit('on-emit-update-default-charges', this.isUpdateDefaultCharges)
    },
    getChargeItems(id) {
      itemChargeService
        .fetchAllByItemId(id)
        .then(result => {
          const { data } = result
          data.forEach(item => {
            this.oldChargeItems.push(item)
          })
        })
        .then(() => {
          this.setDefaultChargeItems()
        })
    },
    getDefaultChargeItems(id) {
      productsService
        .fetchDefaultCharges(Array.of(id))
        .then(result => {
          const { data } = result
          data.forEach(item => {
            this.oldChargeItems.push({
              id: item.id,
              chargeType: 'prep_material',
              costId: item.costId,
              title: item.title,
              rate: item.cost,
              count: 0,
            })
          })
        })
        .then(() => {
          this.setDefaultChargeItems()
        })
    },
    getCostItems() {
      const getItem = 'page=0&size=500'
      prepMaterialInventoryService.fetchAll(getItem).then(response => {
        this.costItems.splice(0, this.costItems.length)
        response.data.content.forEach(item => {
          this.costItems.push({
            id: item.id,
            title: item.title,
            salePrice: item.salePrice,
          })
        })
      })
    },
    createItemCharge() {
      this.chargeItems.push({
        rowId: uuidv4(),
        rowIsShipmentCharge: false,
        rowIsPrepMaterial: true,
        id: null,
        itemId: this.itemId,
        costId: 0,
        costItem: '',
        chargeType: 'prep_material',
        rate: 0,
        count: 1,
        notes: '',
      })
    },
    checkAddItemCharge() {
      if (this.chargeItems.length > 0) {
        const items = filter(this.chargeItems, item => item.count === 0 || item.rate === 0)
        return items.length === 0
      }
      return false
    },
    addBoxCharge() {
      if (this.chargeItems.length === 0) {
        this.createItemCharge()
        store.dispatch('charge/addCharge', this.chargeItems)
      } else if (this.checkAddItemCharge()) {
        this.createItemCharge()
        store.dispatch('charge/addCharge', this.chargeItems)
      } else {
        Notify.warning('Please enter valid charge value.')
      }
    },
    removeBoxCharge(index) {
      this.chargeItems.splice(index, 1)
      store.dispatch('charge/removeCharge', index)
    },
    changeBoxChargeType(e, rowId) {
      const chargeItem = find(this.chargeItems, item => item.rowId === rowId)
      this.setDefaultBoxChargeItemData(chargeItem)
      if (e.value === 'box_prep_material' || e.value === 'prep_material') {
        if (e.value === 'prep_material') {
          chargeItem.rowIsShipmentCharge = true
        } else {
          chargeItem.rowIsShipmentCharge = false
        }
        chargeItem.rowIsPrepMaterial = true
      } else {
        chargeItem.rowIsPrepMaterial = false
        chargeItem.rowIsShipmentCharge = true
      }
    },
    setDefaultBoxChargeItemData(item) {
      const chargeItem = item
      chargeItem.id = null
      chargeItem.itemId = this.itemId
      chargeItem.costId = 0
      chargeItem.costItem = ''
      chargeItem.rate = 0
      chargeItem.count = 1
      chargeItem.notes = ''
    },
    setBoxChargeItemData(e, rowId) {
      const costItem = e.selectedItem
      const chargeItem = find(this.chargeItems, item => item.rowId === rowId)
      if (costItem) {
        chargeItem.id = null
        chargeItem.itemId = this.itemId
        chargeItem.costId = costItem.id
        chargeItem.costItem = costItem.title
        chargeItem.rate = costItem.salePrice
        chargeItem.count = 1
        chargeItem.notes = null
      } else {
        chargeItem.chargeType = 'box_prep_material'
        this.setDefaultBoxChargeItemData(chargeItem)
      }
    },
    setDefaultChargeItems() {
      if (this.oldChargeItems.length > 0) {
        store.dispatch('charge/clearCharges')
        this.chargeItems.splice(0, this.chargeItems.length)
        this.oldChargeItems.forEach(item => {
          const chargeType = item.chargeType === 'prep_material' ? 'prep_material' : item.chargeType
          const rowType = item.chargeType === 'prep_material'
          const chargeItem = {
            rowId: uuidv4(),
            rowIsShipmentCharge: !rowType,
            rowIsPrepMaterial: rowType,
            id: item.id,
            itemId: item.itemId,
            costId: item.costId,
            costItem: item.title,
            chargeType: chargeType,
            rate: item.rate,
            count: item.count,
            notes: item.notes,
          }
          this.chargeItems.push(chargeItem)
        })
        store.dispatch('charge/addCharge', this.chargeItems)
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
