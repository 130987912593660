<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 px-0">
        <b-breadcrumb class="breadcrumb-chevron">
          <b-breadcrumb-item :to="{ name: 'dashboard-summary' }">
            Home
          </b-breadcrumb-item>
          <b-breadcrumb-item> Account </b-breadcrumb-item>
          <b-breadcrumb-item> Accounting </b-breadcrumb-item>
          <b-breadcrumb-item :to="{ name: 'accounting-invoice-list' }">
            Invoices
          </b-breadcrumb-item>
          <b-breadcrumb-item active>
            Invoice Items
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
      <div class="col-md-12 px-0">
        <dx-data-grid
          id="invoiceDetailGrid"
          ref="invoiceDetailGridRef"
          :data-source="dataSource"
          :height="setHeight"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :hover-state-enabled="true"
          :row-alternation-enabled="false"
          @content-ready="onContentReady"
          @toolbar-preparing="onToolbarPreparing($event)"
        >
          <dx-sorting mode="single" />
          <!-- <dx-export :enabled="true" :allow-export-selected-data="true" /> -->
          <dx-load-panel :enabled="true" file-name="Invoice Items" />
          <dx-remote-operations :paging="true" :filtering="true" />
          <dx-column data-field="title" :width="600">
            <dx-util-required-rule />
          </dx-column>
          <dx-column data-field="upc" caption="UPC" />
          <dx-column data-field="asin" caption="ASIN" />
          <dx-column data-field="msku" caption="MSKU" />
          <dx-column data-field="fnsku" caption="FNSKU" />
          <dx-column data-field="storeName" />
          <dx-column data-field="quantity" />
          <dx-column data-field="shipTime" data-type="date" />
          <dx-column data-field="batchName" />
          <template #filterToolbar>
            <div class="d-flex flex-row align-items-center">
              <dx-util-button
                type="normal"
                styling-mode="contained"
                icon="exportxlsx"
                text="Export Excel"
                @click="searchByFilter()"
              />
            </div>
          </template>
          <dx-column data-field="extraCharges" caption="Charges" cell-template="extraChargesTemplate" />
          <template #extraChargesTemplate="{ data }">
            {{ getCurrencyFormat(data) }}
          </template>
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="80" :fixed="true" fixed-position="left" />
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                ref="btnAddCharge"
                hint="Charge Details"
                icon="bi bi-coin"
                type="success"
                @click="onClickAddCharges(data)"
              />
            </div>
          </template>
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
        </dx-data-grid>
      </div>
    </div>
    <div class="col-md-12">
      <!--Begin:: Charge Component-->
      <dx-util-popup
        id="popupCharges"
        ref="popupChargesRef"
        :show-close-button="true"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-title="true"
        :height="720"
        content-template="popup-content"
        title="Invoice Item Charges"
        @showing="onShowingChargesPopup"
        @hidden="onHiddenChargesPopup"
      >
        <dx-util-position at="center" my="center" />
        <template #popup-content>
          <charge-item v-if="charges.isCreated" :is-disabled="!hasPermission" :item-id="charges.itemId" :visible-default-charge="false" />
        </template>
        <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="closeChargesOptions" />
      </dx-util-popup>
    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import ChargeItem from '@components/charge/TheChargeItem.vue'
import { currencyFormatter } from '@core/utils/filter'
import { invoiceDetailSource } from './invoiceDetailStore'

export default {
  components: {
    ChargeItem,
  },
  mixins: [GridBase],
  data() {
    return {
      dataSource: invoiceDetailSource,
      charges: {
        itemId: null,
        isCreated: false,
      },
      chargeUpdateAllowed: false,
    }
  },
  computed: {
    dataGrid() {
      const grid = this.$refs.invoiceDetailGridRef.instance
      return grid
    },
    id() {
      return this.$route.params.id
    },
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    closeChargesOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          const popup = this.$refs.popupChargesRef.instance
          popup.hide()
        },
      }
    },
  },
  mounted() {
    const dataSource = this.dataGrid.getDataSource()
    dataSource.filter([['id', '=', this.$route.params.id]])
    dataSource.load()
  },
  methods: {
    getCurrencyFormat(data) {
      return currencyFormatter(data.value)
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.push({
        widget: 'dxButton',
        showText: 'always',
        options: {
          icon: 'exportxlsx',
          text: 'Export to Excel',
          onClick: () => {
            e.component.exportToExcel(false)
          },
        },
        location: 'after',
      })
    },
    isActiveChargeAction(e) {
      return e.row.data.invoiceId && e.row.data.invoiceId !== null
    },
    onClickAddCharges(e) {
      this.chargeUpdateAllowed = !this.isActiveChargeAction(e)
      const { data } = e.row
      const popup = this.$refs.popupChargesRef.instance
      this.charges.itemId = data.id
      popup.show()
    },
    onShowingChargesPopup() {
      this.charges.isCreated = true
    },
    onHiddenChargesPopup() {
      this.charges = {
        itemId: null,
        isCreated: false,
      }
    },
    saveChargesOptions() {
      return {
        text: 'Save Charges',
        type: 'success',
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.assignLocationPopup.hide()
        },
      }
    },
  },
}
</script>
