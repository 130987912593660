import { isToday } from './utils'

export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

export const title = (value, replacer = ' ') => {
  if (!value) return ''
  const str = value.toString()

  const arr = str.split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })
  return capitalizedArray.join(' ')
}

export const avatarText = value => {
  if (!value) return ''
  const nameArray = value.split(' ')
  return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = {
  month: 'numeric',
  day: 'numeric',
  year: 'numeric',
}) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatDateTime = (value, formatting = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
}) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const currencyFormatter = value => {
  if (!value) return value
  const sansDec = value.toFixed(2)
  const formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return `$${formatted}`
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')

/**
 * Get the standard time abbreviation from a given daylight saving time abbreviation
 * @param {String} timeZoneAbbreviation daylight saving time abbreviation
 * @returns {String} standard time abbreviation
 */
export const getStandardTimeAbbreviation = timeZoneAbbreviation => {
  const daylightSavingTimeAbbreviations = {
    // AMERICA
    ADT: 'AST', // Atlantic Daylight Time -> Atlantic Standard Time
    EDT: 'EST', // Eastern Daylight Time -> Eastern Standard Time
    CDT: 'CST', // Central Daylight Time -> Central Standard Time
    MDT: 'MST', // Mountain Daylight Time -> Mountain Standard Time
    PDT: 'PST', // Pacific Daylight Time -> Pacific Standard Time
    AKDT: 'AKST', // Alaska Daylight Time -> Alaska Standard Time
    // OUTSIDE AMERICA
    HDT: 'HST', // Hawaii-Aleutian Daylight Time -> Hawaii-Aleutian Standard Time
    ACT: 'ACST', // Australian Central Daylight Time -> Australian Central Standard Time
    AET: 'AEST', // Australian Eastern Daylight Time -> Australian Eastern Standard Time
    AGT: 'ART', // Argentina Time -> Argentina Standard Time
    CNT: 'NST', // Newfoundland Time -> Newfoundland Standard Time
    CTT: 'CST', // China Time -> Central Standard Time (China)
    ECT: 'CET', // Eastern Caribbean Time -> Central European Time
    IET: 'EST', // Indiana Eastern Time -> Eastern Standard Time
    PNT: 'MST', // Phoenix Time -> Mountain Standard Time
    PRT: 'AST', // Puerto Rico Time -> Atlantic Standard Time
    SST: 'SBT', // Samoa Standard Time -> Solomon Islands Time
    VST: 'ICT', // Vietnam Standard Time -> Indochina Time
  }

  return daylightSavingTimeAbbreviations[timeZoneAbbreviation] || timeZoneAbbreviation
}
