import { ObjectToQueryString } from '@robustshell/utils/index'

const getMapFromArray = data => data.reduce((acc, item) => {
  if (Array.isArray(item)) {
    acc[item[0]] = item[2]
  }
  return acc
}, {})

export default class Filters {
  constructor(filter) {
    this.filters = filter
  }

  toCreatePostData() {
    if (Array.isArray(this.filters)) {
      const data = getMapFromArray(this.filters)
      return data
    }
    return null
  }

  toCreateGetData() {
    if (Array.isArray(this.filters)) {
      const data = getMapFromArray(this.filters)
      return ObjectToQueryString(data)
    }
    return null
  }

  toCreateGetDataFromObject() {
    return ObjectToQueryString(this.filters)
  }
}
