/* eslint-disable no-unused-vars */
import isNumber from 'lodash/isNumber'
import productsService from '@/http/requests/product/productsService'

const getDefaultState = () => ({
  charges: [],
  chargeItems: [],
  chargeErrors: [],
})

export default {
  namespaced: true,
  state() {
    return getDefaultState()
  },
  getters: {
    getCharges: state => state.chargeItems,
    getChargeErrors: state => state.chargeErrors,
  },
  mutations: {
    addCharge(state, payload) {
      state.charges = [...payload]
    },
    removeCharge(state, payload) {
      state.charges.splice(payload, 1)
    },
    clearCharges(state) {
      Object.assign(state, getDefaultState())
    },
    setCharges(state, payload) {
      state.chargeErrors.splice(0, state.chargeErrors.length)
      state.charges.forEach(charge => {
        if (isNumber(charge.rate) && charge.rate > 0) {
          const newCharge = {
            id: charge.id,
            itemId: payload,
            costId: charge.costId,
            title: charge.costItem,
            chargeType: charge.chargeType === 'prep_material' || charge.chargeType === 'box_prep_material' ? 'prep_material' : charge.chargeType,
            rate: charge.rate,
            count: charge.count,
            notes: charge.notes,
          }
          state.chargeItems.push(newCharge)
        } else {
          state.chargeErrors.push(`Rate (${charge.rate}) could not be added.`)
        }
      })
    },
    setDefaultCharges(state, payload) {
    },
  },
  actions: {
    addCharge(context, payload) {
      context.commit('addCharge', payload)
    },
    removeCharge(context, payload) {
      context.commit('removeCharge', payload)
    },
    clearCharges(context) {
      context.commit('clearCharges')
    },
    setCharges(context, payload) {
      context.commit('setCharges', payload)
    },
    async setDefaultCharges(context, payload) {
      const result = await productsService.fetchDefaultCharges(payload)
      const { data } = result
      const charges = []
      data.forEach(charge => {
      })
      context.commit('setDefaultCharges', charges)
    },
  },
}
