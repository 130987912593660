import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  async fetchByQuery(filter, pageable) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.products.Controller.findByQuery(pageable), filter)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async getByQueryForWorkingBatch(filter) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.products.Controller.getByQueryForWorkingBatch(), filter)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get products by id
  async fetchById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.products.Controller.findById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async fetchByIdList(ids) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.products.Controller.fetchByIdList(), ids)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async fetchDefaultCharges(ids) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.products.Controller.findDefaultCharges(), ids)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async findProductAndCharges(ids) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.products.Controller.findProductAndCharges(), ids)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get products by upc
  // async fetchByUPC(code) {
  //   const promise = new Promise((resolve, reject) => {
  //     axios
  //       .get(endpoints.products.Controller.findByUPC(code))
  //       .then(response => {
  //         resolve({
  //           data: response.data.body,
  //         })
  //       })
  //       .catch(error => reject(error))
  //   })

  //   promise.then(
  //     result => result,
  //     error => error,
  //   )

  //   return promise
  // },

  // get products by asin
  async findByASINAndPackType(params) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.products.Controller.findByASINAndPackType(params))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get products by fnsku
  async fetchDetailsById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.products.Controller.findDetailsById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get products by title
  // async fetchByTitle(title) {
  //   const promise = new Promise((resolve, reject) => {
  //     axios
  //       .get(endpoints.products.Controller.findByTitle(title))
  //       .then(response => {
  //         resolve({
  //           data: response.data.body,
  //         })
  //       })
  //       .catch(error => reject(error))
  //   })

  //   promise.then(
  //     result => result,
  //     error => error,
  //   )

  //   return promise
  // },

  // create products
  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.products.Controller.create(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async assignPlan(itemList, planId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.products.Controller.assignPlan(planId), itemList)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async updatePlan(productId, planId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.products.Controller.updatePlan(productId, planId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async updateDefaultCharges(id, data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.products.Controller.updateDefaultCharges(id), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // update products
  async update(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.products.Controller.update(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // delete products
  async deleteById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.products.Controller.delete(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // delete multiple products given by id list
  async deleteMany(idList) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.products.Controller.deleteMany(), idList)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async fetchCodesById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.products.Controller.findCodesById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async fetchProductDataByIds(ids) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.products.Controller.findProductDataByIds(), ids)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async getProductBundleInfo(companyId, asin) {
    const result = await axios.get(endpoints.products.Controller.getProductBundleInfo(companyId, asin))
    return result.data.body
  },
  async getProductDataById(productId) {
    const result = await axios.get(endpoints.products.Controller.getProductDataById(productId))
    return result.data.body
  },
}
